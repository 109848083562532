@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"



















































.video__player
  iframe
    width: 1030px
    height: 577px

    +mq-xl
      max-width: 992px
      height: 567px

    +mq-l
      max-width: 720px
      height: 414px

    +mq-m
      width: 100%
      max-height: 414px

    +mq-s
      width: 100%
      max-height: 220px

    +mq-xs
      width: 100%
      max-height: 180px
