@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"























































































































.video
  &__title
    padding: 25px

    h2
      font-size: 20px

  &__skeleton-title
    width: 100%
    height: 62px !important
    margin-top: 25px

.wrapper
  +mq-s
    max-width: 100%
