@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"





















































































































































.download
  .download-wrapper
    display: flex
    align-items: center
    justify-content: space-between
    color: white
    background-color: $color-main
    padding: 21px 64px
    margin-bottom: 1px

    +mq-s
      flex-flow: column
      padding: 21px

      h4
        margin-bottom: 20px

    &__button
      background-color: $color-secondary
      height: 48px

      &__text
        padding-left: 10px
